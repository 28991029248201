import React, { useState, useContext } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import RegionDetails from './RegionDetails';
import { AuthContext } from '../contexts/AuthContext';
import { fetchRegionData } from '../services/api';
import SnackbarResult from './SnackbarResult'; // Импортируем новый компонент

const MapCell = ({
                     pointId,
                     chipLabel,
                     chipColor,
                     bottomLabel,
                 }) => {
    const token = useContext(AuthContext);

    const [openDialog, setOpenDialog] = useState(false);
    const [regionData, setRegionData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    // Состояние для снекбара
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');

    const handleOpen = () => {
        setOpenDialog(true);
        setIsLoading(true);
        setRegionData(null);

        console.log("pointId: ", pointId);
        fetchRegionData(token, pointId)
            .then(data => {
                setRegionData(data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching region data:', error);
                setIsLoading(false);
            });
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    // Функция для отображения снекбара с сообщением и типом
    const showMySnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <>
            <Box
                onClick={handleOpen}
                sx={{
                    width: '100%',
                    aspectRatio: '1 / 1',
                    bgcolor: '#ecf0f1',
                    borderRadius: 2,
                    boxShadow: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    p: 0,
                    m: 0,
                    overflow: 'hidden',
                    border: chipLabel === '📍' || chipLabel === '🚩'
                        ? '3px solid #2c3e50'
                        : '0.1px solid #000',
                    boxSizing: 'border-box',
                    transition: 'transform 0.2s ease-in-out',
                    cursor: 'pointer',
                    '&:active': {
                        transform: 'scale(0.97)',
                    }
                }}
            >
                {/* Верхняя зона */}
                <Box
                    sx={{
                        p: 0.4,
                        bgcolor: chipColor,
                        height: '30%',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: 1,
                        userSelect: 'none'
                    }}
                >
                    <Typography
                        variant="button"
                        sx={{
                            color: 'text.secondary',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: '100%',
                            textAlign: 'center',
                        }}
                    >
                        {chipLabel}
                    </Typography>
                </Box>

                {/* Нижняя зона */}
                <Box
                    sx={{
                        height: '40%',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        px: 1,
                        userSelect: 'none'
                    }}
                >
                    <Typography
                        variant="caption"
                        sx={{
                            color: 'text.secondary',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: '100%',
                            textAlign: 'center',
                        }}
                    >
                        {bottomLabel}
                    </Typography>
                </Box>
            </Box>
            <RegionDetails
                open={openDialog}
                onClose={handleClose}
                title={regionData?.title}
                subtitle={regionData?.subtitle}
                icon={regionData?.icon}
                extraInfo={regionData?.extraInfo}
                routeInfo={regionData?.routeInfo}
                steps={regionData?.steps}
                commandValue={regionData?.commandValue}
                canTravel={regionData?.canTravel}
                isLoading={isLoading}
                token={token}
                pointId={pointId}
                onShowSnackbar={showMySnackbar}
            />

            <SnackbarResult
                open={snackbarOpen}
                severity={snackbarSeverity}
                message={snackbarMessage}
                onClose={handleSnackbarClose}
            />
        </>
    );
};

export default MapCell;