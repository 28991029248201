// src/auth.js

import FingerprintJS from "@fingerprintjs/fingerprintjs";

export async function authenticate(initData) {

    const userAgent = navigator.userAgent || '';
    const platform = window.Telegram.WebApp.platform || '';
    const version = window.Telegram.WebApp.version || '';

    let visitorId = '';
    try {
        const fp = await FingerprintJS.load();
        const fpResult = await fp.get();
        visitorId = fpResult.visitorId;
    } catch (e) {
        // Если по какой-то причине FingerprintJS не отработал — не критично
        console.warn('Fingerprint failed:', e);
    }

   // console.log('Отправка GET-запроса на авторизацию с initData');
    try {
        const response = await fetch(process.env.REACT_APP_API_URL+`v2/auth?initData=${encodeURIComponent(initData)}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-User-Agent': userAgent,
                'X-Telegram-Platform': platform,
                'X-Telegram-Version': version,
                'X-Visitor-Id': visitorId
            },
        });

     //   console.log('Ответ от сервера:', response.status);

        if (!response.ok) {
            // Попытка парсинга тела ошибки
            let errorData;
            try {
                errorData = await response.json();
            } catch (parseError) {
             //   console.error('Не удалось распарсить тело ошибки:', parseError);
                errorData = { message: 'Unknown error occurred' };
            }

            // Создание кастомного объекта ошибки
            const error = new Error('Authentication failed');
            error.response = {
                status: response.status,
                data: errorData
            };
            throw error;
        }

        const data = await response.json();
     //   console.log('Полученные данные от сервера:', data);
        return data.token; // JWT
    } catch (error) {
  //      console.error('Error during authentication:', error);
        throw error;
    }
}