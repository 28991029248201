// src/components/widgets/MapCellsWidget.jsx

import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid'; // Внимание: вы импортировали Grid2
import MapCell from '../MapCell';       // Путь уточните, если MapCell лежит в другом месте

/**
 * Виджет клеток карты (7x7).
 * Параметры:
 *   cells (array) - массив объектов { pointId, chipColor, chipLabel, bottomLabel, ... }
 */
export default function MapCellsWidget({ cells = [] }) {
    return (
        <Card
            sx={{
                borderRadius: 2,
                overflowX: 'auto',  // Прокрутка по горизонтали
                overflowY: 'visible',
                width: '100%',
                boxShadow: 'none',
                // mb: 2 — если хотите нижний отступ
            }}
        >
            <CardContent
                sx={{
                    px: { xs: 0, sm: 1, md: 2 }, // Адаптивные горизонтальные внутренние отступы
                    py: { xs: 2, sm: 3, md: 4 }, // Адаптивные вертикальные
                }}
            >
                <Grid container spacing={0} columns={7}>
                    {cells.map((cell) => (
                        <Grid key={cell.pointId} item xs={1}>
                            <MapCell
                                pointId={cell.pointId}
                                chipColor={cell.chipColor}
                                chipLabel={cell.chipLabel}
                                bottomLabel={cell.bottomLabel}
                            />
                        </Grid>
                    ))}
                </Grid>
            </CardContent>
        </Card>
    );
}