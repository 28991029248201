import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

const MainTabs = ({ children }) => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box bgcolor="#f5f5f5" sx={{ width: '100%' }}>
            <Tabs
                value={value}
                centered
                onChange={handleChange}
                textColor="primary"
                indicatorColor="primary"
            >
                <Tab label="Map" />
                <Tab label="Profile" />
                <Tab label="Guild" />
            </Tabs>
            <Box sx={{ px: 0, py:2 }}>
                {React.Children.toArray(children)[value]}
            </Box>
        </Box>
    );
};

export default MainTabs;