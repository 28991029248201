// src/components/ProfileTab.js
import React, { useState, useEffect, useContext } from 'react';


import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography'; // <<< NEW >>>
import CardActionArea from "@mui/material/CardActionArea";
import DialogContent from "@mui/material/DialogContent";


import ProgressChip from './ProgressChip';
import ProfileCell from './ProfileCell';
import IconText from './IconText';
import ProgressCell from "./ProgressCell";

import MilitaryTechRoundedIcon from '@mui/icons-material/MilitaryTechRounded';
import ShieldRoundedIcon from '@mui/icons-material/ShieldRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import WaterDropRoundedIcon from '@mui/icons-material/WaterDropRounded';
import OfflineBoltRoundedIcon from '@mui/icons-material/OfflineBoltRounded';
import HikingRoundedIcon from '@mui/icons-material/HikingRounded';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';

import Collapse from '@mui/material/Collapse';               // << NEW
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'; // << NEW
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';     // << NEW


import { AuthContext } from '../contexts/AuthContext';
import {connectSSE, sendTabChange} from '../services/api';
import {parseMessage} from "../utils/markdown";
import ProfileImgCell from "./ProfileImgCell";
import AdminPanel from './AdminPanel';

const iconMapping = {
    MilitaryTechRoundedIcon: <MilitaryTechRoundedIcon />,
    ShieldRoundedIcon: <ShieldRoundedIcon />,
    StarRoundedIcon: <StarRoundedIcon />,
    FavoriteRoundedIcon: <FavoriteRoundedIcon />,
    WaterDropRoundedIcon: <WaterDropRoundedIcon />,
    OfflineBoltRoundedIcon: <OfflineBoltRoundedIcon />,
    HikingRoundedIcon: <HikingRoundedIcon />,
    ReportProblemRoundedIcon : <ReportProblemRoundedIcon />,
    InfoRoundedIcon: <InfoRoundedIcon />,
};

function renderDynamicIcon(iconName, color) {
    const IconComponent = iconMapping[iconName];
    if (!IconComponent) return null; // Если не нашли, не рендерим иконку
    // Оборачиваем в cloneElement, чтобы можно было задать color/style
    return React.cloneElement(IconComponent, {
        fontSize: 'large',
        style: { color } });
}

const ProfileTab = () => {
    const [isLoading, setIsLoading] = useState(true);

    const token = useContext(AuthContext);

    // Основные состояния профиля
    const [nameValue, setNameValue] = useState("name");
    const [guildValue, setGuildValue] = useState("–");
    const [moneyValue, setMoneyValue] = useState("0");
    const [bankValue, setBankValue] = useState("0");

    const [locationValue, setLocationValue] = useState("0#0");
    const [rankValue, setRankValue] = useState("0");
    const [armorValue, setArmorValue] = useState("0");
    const [nobleDaysValue, setNobleDaysValue] = useState("0");
    const [levelValue, setLevelValue] = useState("LVL 0");
    const [expValue, setExpValue] = useState("0% 0/0");
    const [expPercentageValue, setExpPercentageValue] = useState("0");

    // Состояния для HP
    const [hpPercentageValue, setHPPercentageValue] = useState(0);
    const [hpLabelMainValue, setHPLabelMainValue] = useState("0 / 0");
    const [hpLabelSecondValue, setHPLabelSecondValue] = useState("0%");

    // Состояния для Mana
    const [manaPercentageValue, setManaPercentageValue] = useState(0);
    const [manaLabelMainValue, setManaLabelMainValue] = useState("0 / 0");
    const [manaLabelSecondValue, setManaLabelSecondValue] = useState("0%");

    // Состояния для Stamina
    const [staminaPercentageValue, setStaminaPercentageValue] = useState(0);
    const [staminaLabelMainValue, setStaminaLabelMainValue] = useState("0 / 0");
    const [staminaLabelSecondValue, setStaminaLabelSecondValue] = useState("00 min");

    // Состояния для Steps
    const [stepsPercentageValue, setStepsPercentageValue] = useState(0);
    const [stepsLabelMainValue, setStepsLabelMainValue] = useState("0 / 0");
    const [stepsLabelSecondValue, setStepsLabelSecondValue] = useState("00 min");

    // <<< NEW: Состояния для новой "alert-карточки"
    const [alertCardTitle, setAlertCardTitle] = useState('');
    const [alertCardBody, setAlertCardBody] = useState('');
    const [alertCardIcon, setAlertCardIcon] = useState('');
    const [alertCardColor, setAlertCardColor] = useState('');
    const [alertCardTextColor, setAlertCardTextColor] = useState('');

    const [alertCardExpanded, setAlertCardExpanded] = useState(false);

    const [showAdminPanel, setShowAdminPanel] = useState("false");
    const [adminPanelOpen, setAdminPanelOpen] = useState(false);

    useEffect(() => {
        if (!token) return;
        // Отправляем запрос на смену активной вкладки на 'profile'
        sendTabChange(token, 'profile');

      //  console.log('Установка SSE-соединения с токеном:', token);

        const updateHandlers = {
            nameUI: (value) => { setNameValue(value); setIsLoading(false); },
            guildNameUI: setGuildValue,
            UIMoneyGold: setMoneyValue,
            UIMoneyBank: setBankValue,

            locationUI: (value) => setLocationValue(parseMessage(value)),

            rankUI: (value) => setRankValue(String(value)),
            armorUI: (value) => setArmorValue(String(value)),
            nobleDaysUI: setNobleDaysValue,
            levelUI: setLevelValue,
            expUI: setExpValue,
            expPercentageUI: setExpPercentageValue,

            hpPercentageUI: setHPPercentageValue,
            hpLabelMainUI: setHPLabelMainValue,
            hpLabelSecondUI: setHPLabelSecondValue,

            manaPercentageUI: setManaPercentageValue,
            manaLabelMainUI: setManaLabelMainValue,
            manaLabelSecondUI: setManaLabelSecondValue,

            staminaPercentageUI: setStaminaPercentageValue,
            staminaLabelMainUI: setStaminaLabelMainValue,
            staminaLabelSecondUI: setStaminaLabelSecondValue,

            stepsPercentageUI: setStepsPercentageValue,
            stepsLabelMainUI: setStepsLabelMainValue,
            stepsLabelSecondUI: setStepsLabelSecondValue,

            alertCardTitleUI: setAlertCardTitle,
            alertCardBodyUI: setAlertCardBody,
            alertCardIconUI: setAlertCardIcon,
            alertCardColorUI: setAlertCardColor,
            alertCardTextColorUI: setAlertCardTextColor,

            showAdminPanelUI: (value) => {
                setShowAdminPanel(String(value));
            },

        };

        const eventSource = connectSSE(token, updateHandlers, (err) => {
            // Обработка ошибки, если необходимо
            console.error('Ошибка SSE-соединения:', err);
        }, 'profile'); // Передаём 'profile' как параметр tab

        // Очистка при размонтировании компонента
        return () => {
            eventSource.close();
        };
    }, [token]);

    if (isLoading || !token) {
        return (
            <Card sx={{ padding: 2 }}>
                <Skeleton variant="text" height={50} />
                <Box sx={{ height: 50 }} /> {/* Пробел 50px */}
                <Skeleton variant="rectangular" height={100} />
                <Box sx={{ height: 50 }} /> {/* Пробел 50px */}
                <Skeleton variant="text" height={50} />
            </Card>
        );
    }

    const isAlertCardReady =
        alertCardTitle &&
        alertCardBody &&
        alertCardIcon &&
        alertCardColor&&
    alertCardTextColor;


    return (
        <Container
            maxWidth="md" // Ограничение ширины: xs, sm, md, lg, xl
            sx={{
                bgcolor: 'background.default', // Цвет фона
                py: 2, // Отступы сверху и снизу
                px: { xs: 1, sm: 2, md: 3 },
            }}
        >

            {isAlertCardReady && (
                <Card
                    sx={{
                        mb: 2,
                        borderRadius: 2,
                        backgroundColor: alertCardColor,
                        color: "black",
                        boxShadow: 'none',
                    }}
                >
                    <CardActionArea
                        onClick={() => setAlertCardExpanded(!alertCardExpanded)}
                        sx={{
                            px: { xs: 2, sm: 3 },
                            py: { xs: 1, sm: 2 },
                            display: 'block', // Чтобы содержимое тянулось по всей ширине
                        }}
                    >
                        {/* ШАПКА */}
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            {/* Левая часть: иконка + заголовок */}
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{ mr: 2 }}>
                                    {renderDynamicIcon(alertCardIcon, alertCardTextColor)}
                                </Box>
                                <Typography variant="body1" sx={{ color: 'inherit' }}>
                                    {alertCardTitle}
                                </Typography>
                            </Box>

                            {alertCardExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </Box>

                        {/* Контент */}
                        <Collapse in={alertCardExpanded} timeout="auto" unmountOnExit>
                            <Box sx={{ mt: 1 }}>
                                <Typography
                                    variant="body2"
                                    sx={{ color: 'inherit' }}
                                    dangerouslySetInnerHTML={{ __html: alertCardBody }}
                                />
                            </Box>
                        </Collapse>
                    </CardActionArea>
                </Card>
            )}


            <Box
                sx={{
                    px: { xs: 1, sm: 2, md: 3 }, // Горизонтальные отступы
                    py: { xs: 2, sm: 4, md: 6 }, // Вертикальные отступы
                    height: '100%',
                    bgcolor: '#fff',
                    borderRadius: 2
                }}
            >
                <Grid container spacing={{ xs: 1, sm: 2, md: 3 }} sx={{ height: '100%' }}>
                    {/* Левая колонка */}
                    <Grid item xs={3} sm={4} sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Box
                             sx={{
                                 backgroundColor: '#F1F2F6', // Фон карточки
                                 borderRadius: '12px',         // Закругленные углы
                                 boxShadow: 0,                  // Тень
                                 padding: 2,                    // Внутренний отступ
                                 flex: 1,
                                 display: 'flex',
                                 flexDirection: 'column',
                                 alignItems: 'center',
                                 justifyContent: 'flex-start'
                             }}
                         >
                            <Avatar
                                src="/img/userpic.png"
                                alt="User Avatar"
                                sx={{
                                    width: { xs: 50, sm: 100, md: 150 }, // Уменьшение размера на мобильных
                                    height: { xs: 50, sm: 100, md: 150 },
                                    borderRadius: 2, // Закруглённые углы (в пикселях)
                                    mb: 2,
                                }}
                            />

                            <IconText
                                icon={<MilitaryTechRoundedIcon />}
                                text={rankValue}
                            />
                            <IconText
                                icon={<ShieldRoundedIcon />}
                                text={armorValue}
                            />
                            <Box sx={{ mt: 2 }}> {/* Устанавливаем отступ сверху */}
                                <Chip
                                    icon={<StarRoundedIcon />}
                                    label={nobleDaysValue}
                                    color="secondary"
                                    variant="outlined"
                                />
                            </Box>
                        </Box>
                    </Grid>

                    {/* Правая колонка */}
                    <Grid item xs={9} sm={8} sx={{ height: '100%' }}>
                        <Stack spacing={{ xs: 1, sm: 2, md: 3 }} sx={{ height: '100%' }}>
                            <ProfileCell title="Name" content={nameValue} />
                            <ProfileCell title="Guild" content={guildValue} />
                            <ProfileImgCell title="Money" icon1="gold.png" text1={moneyValue} icon2="bank.png" text2={bankValue} />
                            <ProfileCell title="Location" content={locationValue} />
                        </Stack>
                    </Grid>
                </Grid>

                <Box sx={{ height: 15 }}></Box>

                <Grid
                    container
                    spacing={{ xs: 1, sm: 2, md: 3 }}
                    sx={{
                        height: { xs: 35, sm: 50 }, // Общая высота для строки
                        alignItems: 'center', // Центрирование элементов по вертикали
                    }}
                >
                    {/* Чип */}
                    <Grid
                        item
                        xs={3}
                        sm={4}
                        sx={{
                            height: '100%', // Полная высота родителя
                            display: 'flex',
                            alignItems: 'center', // Центрирование содержимого чипа
                        }}
                    >
                        <Chip
                            label={levelValue}
                            color="secondary"
                            sx={{
                                width: '100%', // Чип занимает всю ширину
                                height: '100%', // Чип занимает всю высоту
                                borderRadius: 2, // Закруглённые углы
                            }}
                        />
                    </Grid>

                    {/* Прогресс-чип */}
                    <Grid
                        item
                        xs={9}
                        sm={8}
                        sx={{
                            height: '100%', // Полная высота родителя
                            display: 'flex',
                            alignItems: 'center', // Центрирование содержимого прогресс-бара
                        }}
                    >
                        <ProgressChip
                            value={expPercentageValue}
                            label={expValue}
                            color="#2ED573"
                            sx={{
                                width: '100%', // Прогресс-бар занимает всю ширину
                                height: '100%', // Прогресс-бар занимает всю высоту
                            }}
                        />
                    </Grid>
                </Grid>
            </Box>

            <Box sx={{ height: 20 }}></Box>

            <Box
                sx={{
                    px: { xs: 1, sm: 2, md: 3 }, // Горизонтальные отступы
                    py: { xs: 2, sm: 4, md: 6 }, // Вертикальные отступы
                    height: '100%',
                    bgcolor: '#fff',
                    borderRadius: 2
                }}
            >
                <Stack
                    spacing={2} // Отступы между ProgressCell
                    sx={{
                        width: '100%', // Гарантируем, что Stack занимает всю ширину родителя
                        maxWidth: '100%', // Убираем возможность выйти за пределы родителя
                        boxSizing: 'border-box', // Учитываем отступы и границы
                        overflow: 'hidden', // Скрываем всё, что может выйти за границы
                    }}
                >
                    <ProgressCell
                        height={20}
                        label={hpLabelSecondValue}
                        icon={<FavoriteRoundedIcon style={{ color: '#FF4757' }}/>}
                        progressProps={{
                            value: hpPercentageValue,
                            label: hpLabelMainValue,
                            color: '#FF4757',
                            textColor: '#000',
                        }}
                        sx={{ width: '100%' }} // Элемент занимает доступное пространство
                    />

                    {manaLabelMainValue !== "0 / 0" && (
                        <ProgressCell
                            height={20}
                            label={manaLabelSecondValue}
                            icon={<WaterDropRoundedIcon style={{ color: '#1E90FF' }}/>}
                            progressProps={{
                                value: manaPercentageValue,
                                label: manaLabelMainValue,
                                color: '#1E90FF',
                                textColor: '#000',
                            }}
                            sx={{ width: '100%' }} // Элемент занимает доступное пространство
                        />
                    )}

                    <ProgressCell
                        height={20}
                        label={staminaLabelSecondValue}
                        icon={<OfflineBoltRoundedIcon style={{ color: "#fdcb6e" }}/>}
                        progressProps={{
                            value: staminaPercentageValue,
                            label: staminaLabelMainValue,
                            color: '#7f8c8d',
                            textColor: '#000',
                            backgroundColor:'#ecf0f1',
                        }}
                        sx={{ width: '100%' }} // Элемент занимает доступное пространство
                    />

                    <ProgressCell
                        height={20}
                        label={stepsLabelSecondValue}
                        icon={<HikingRoundedIcon style={{ color: '#FF6348' }}/>}
                        progressProps={{
                            value: stepsPercentageValue,
                            label: stepsLabelMainValue,
                            color: '#7f8c8d',
                            textColor: '#000',
                            backgroundColor:'#ecf0f1',
                        }}
                        sx={{ width: '100%' }} // Элемент занимает доступное пространство
                    />
                </Stack>
            </Box>

            {showAdminPanel === "true" && (
                <Card
                    sx={{
                        mt: 2,
                        borderRadius: 2,
                        p: 2,
                        display: 'flex',
                        justifyContent: 'center', // Центрируем содержимое по горизонтали
                    }}
                >
                    <Button
                        variant="contained"
                        color="info"
                        onClick={() => setAdminPanelOpen(true)}
                    >
                        admin
                    </Button>
                </Card>
            )}
            <Dialog
                fullScreen
                open={adminPanelOpen}
                onClose={() => setAdminPanelOpen(false)}
                scroll="paper" // или уберите если не хотите прокручивать
            >
                <DialogContent
                    sx={{
                        p: 0,
                        overflowY: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <AdminPanel
                        onClose={() => setAdminPanelOpen(false)}
                        token={token}
                    />
                </DialogContent>
            </Dialog>
        </Container>
    );
};

export default ProfileTab;