// src/services/commonHeaders.js

import FingerprintJS from "@fingerprintjs/fingerprintjs";

let cachedData = null;

/**
 * Возвращает объект с нужными данными для заголовков:
 *   - userAgent
 *   - telegram platform
 *   - telegram version
 *   - visitorId
 */
export async function getCommonHeadersData() {
    if (cachedData) {
        return cachedData;
    }

    // Получаем нужные параметры
    const userAgent = navigator.userAgent || "";
    const platform = window.Telegram?.WebApp?.platform || "";
    const version = window.Telegram?.WebApp?.version || "";

    let visitorId = "";

    try {
        const fp = await FingerprintJS.load();
        const fpResult = await fp.get();
        visitorId = fpResult.visitorId;
    } catch (e) {
       // console.warn("Fingerprint failed:", e);
    }

    // Кешируем, чтобы не вызывать FingerprintJS на каждый запрос заново
    cachedData = { userAgent, platform, version, visitorId };
    return cachedData;
}