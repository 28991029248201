import React from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';

import RichText from '../RichText';

export default function MapTradeWidget({
                                           title = 'Trade Center',
                                           marketLabel = '',
                                           auctionLabel = '',
                                       }) {
    return (
        <Card
            sx={{
                borderRadius: 2,
                mb: 2,
                boxShadow: 'none',
                overflow: 'visible',
                p:{ xs: 2, sm: 3, md: 4 },
            }}
        >
            {/* Заголовок карточки */}
            <Box sx={{pb: 1, mb: 1 }}>
                <Typography variant="body1">
                    <RichText content={title} />
                </Typography>
            </Box>

            <Grid container rowSpacing={2} columnSpacing={2}
                  alignItems="stretch"
            >
                <Grid size={6}                                sx={{
                    backgroundColor: '#f0f0f0', // любой цвет, например светло-серый
                    p: 2,                       // отступы внутри, чтобы текст не прилипал к краям
                    borderRadius: 2,           // скруглённые углы (необязательно)
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}>
                    <Typography variant="body2" sx={{mb:2}}>
                        <RichText content={marketLabel} />
                    </Typography>
                    <Button variant="contained" color="secondary" sx={{ width: 100 }}>
                        Market
                    </Button>
                </Grid>

                {/* 1) Первая строка, правая ячейка: Auction текст */}
                <Grid size={6} sx={{
                    backgroundColor: '#f0f0f0', // любой цвет, например светло-серый
                    p: 2,                       // отступы внутри, чтобы текст не прилипал к краям
                    borderRadius: 2,           // скруглённые углы (необязательно)
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}>
                    <Typography variant="body2" sx={{mb:2}}>
                        <RichText content={auctionLabel}/>
                    </Typography>
                    <Button variant="contained" color="secondary" sx={{width: 100}}>
                        Auction
                    </Button>
                </Grid>


            </Grid>
        </Card>
    );
}