// src/App.js
import React from 'react';
import MainTabs from './components/MainTabs';
import ProfileTab from './components/ProfileTab';
import MapTab from './components/MapTab';
import GuildTab from './components/GuildTab';

import { AuthProvider } from './contexts/AuthContext';

function App() {
    return (
        <AuthProvider>
            <MainTabs>
                <MapTab />
                <ProfileTab />
                <GuildTab />
            </MainTabs>
        </AuthProvider>
    );
}

export default App;