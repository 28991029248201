// src/components/DiagramCard.js

import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';

/**
 * Переиспользуемый компонент для отображения
 * линейного графика в карточке.
 *
 * @param {string}  title        - Заголовок карточки
 * @param {Array}   chartData    - Массив объектов с данными
 * @param {string}  xKey         - Название поля, используемого на оси X (например, "week" или "title")
 * @param {string}  yKey         - Название поля, используемого на оси Y (например, "avg" или "amount")
 * @param {string}  usdKey       - Название поля с USD (например, "usd"), которое показывается только в Tooltip
 */
const DiagramCard = ({ title, chartData, xKey, yKey, usdKey }) => {
  return (
      <Card sx={{ mb: 2, overflow: 'hidden' }}>
        <CardContent>
          <Typography variant="body1" gutterBottom>
            {title}
          </Typography>

          {/* Высота графика = 300px, ширина = 100% родителя */}
          <div style={{ width: '100%', height: 300 }}>
            <ResponsiveContainer>
              <LineChart data={chartData} >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={xKey} />
                <YAxis />

                <Tooltip
                    // formatter: значение (в "звёздах") + показ usd в тултипе
                    formatter={(value, name, props) => {
                      // value — это текущее значение yKey (avg / amount)
                      // props.payload[usdKey] — это значение usd
                      const usdRaw = props.payload[usdKey];
                      const starValue = Number(value).toFixed(1);
                      const usdValue = Number(usdRaw).toFixed(1);

                      // Например, отобразим "12.34 звёзд (0.15 USD)"
                      return [`${starValue} [$${usdValue}]`, name];
                    }}
                />


                {/* Линия по yKey (avg или amount) */}
                <Line
                    type="monotone"
                    dataKey={yKey}
                    stroke="#8884d8"
                    name="Value"
                    activeDot={{ r: 8 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </CardContent>
      </Card>
  );
};

export default DiagramCard;