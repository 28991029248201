// src/utils/WidgetRenderer.js
import React from 'react';
import SimpleWidget from '../components/widgets/SimpleWidget';
import InventoryWidget from '../components/widgets/InventoryWidget';
import GuildListWidget from '../components/widgets/GuildListWidget';
import GuildSummaryWidget from '../components/widgets/GuildSummaryWidget';

import MapHeaderWidget from '../components/widgets/MapHeaderWidget';
import MapCellsWidget from '../components/widgets/MapCellsWidget';
import MapTradeWidget from '../components/widgets/MapTradeWidget';

export function renderWidget(widget) {
    const { k, type, value } = widget;

    switch (type) {
        case 'simple': {
            const { title, body } = value || {};
            return (
                <SimpleWidget
                    key={k}
                    title={title || 'Untitled'}
                    body={body || ''}
                />
            );
        }
        case 'inventory': {
            const { title, inventory, filters } = value || {};
            return (
                <InventoryWidget
                    key={k}
                    title={title}
                    inventory={inventory}
                    filters={filters}
                />
            );
        }
        case 'guildSummary': {
            const { avatarUrl, chipLabel, title, body } = value || {};
            return (
                <GuildSummaryWidget
                    key={k}
                    avatarUrl={avatarUrl}
                    chipLabel={chipLabel}
                    title={title}
                    body={body}
                />
            );
        }
        case 'guildList': {
            const { title, cells } = value || {};
            return (
                <GuildListWidget
                    key={k}
                    title={title}
                    cells={cells}
                />
            );
        }

        // Новое: заголовок карты
        case 'mapRegionHeader': {
            const { title, subtitle, icon } = value || {};
            return (
                <MapHeaderWidget
                    key={k}
                    title={title}
                    subtitle={subtitle}
                    icon={icon}
                />
            );
        }

        // Новое: ячейки карты
        case 'mapCells': {
            const { cells } = value || {};
            return (
                <MapCellsWidget
                    key={k}
                    cells={cells}
                />
            );
        }

        case 'mapTrade': {
            // Из бэкенда приходит title, marketLabel, auctionLabel
            const { title, marketLabel, auctionLabel } = value || {};
            return (
                <MapTradeWidget
                    key={k}
                    title={title}
                    marketLabel={marketLabel}
                    auctionLabel={auctionLabel}
                />
            );
        }

        default:
            return null;
    }
}