// src/services/fetchHelper.js

import { getCommonHeadersData } from "./commonHeaders";

/**
 * Универсальная обёртка над fetch, добавляющая "общие" заголовки.
 * @param {string} url - конечная точка API
 * @param {Object} options - настройки запроса (method, body, и т.д.)
 * @param {string} [options.token] - JWT-токен, если нужен
 * @returns {Promise<Response>} - объект Response
 */
export async function fetchWithCommonHeaders(url, options = {}) {
    const { token, method = "GET", body, extraHeaders = {} } = options;

    // Получаем общие данные для заголовков
    const { userAgent, platform, version, visitorId } = await getCommonHeadersData();

    // Формируем заголовки
    const headers = {
        "Content-Type": "application/json",
        // Если нужен токен — добавляем
        ...(token ? { Authorization: `Bearer ${token}` } : {}),

        // Добавляем "специальные" заголовки
        "X-User-Agent": userAgent,
        "X-Telegram-Platform": platform,
        "X-Telegram-Version": version,
        "X-Visitor-Id": visitorId,

        // Если надо переопределить или дополнить
        ...extraHeaders,
    };

    // Собираем полные опции для fetch
    const fetchOptions = {
        method,
        headers,
        credentials: "include", // если нужно
    };

    // Если есть body (для POST/PUT), сериализуем
    if (body) {
        fetchOptions.body = JSON.stringify(body);
    }

    // Вызываем нативный fetch
    return fetch(url, fetchOptions);
}