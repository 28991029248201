// src/components/AdminPanel.js

import React, { useState, useEffect } from 'react';
import { Box, IconButton, Typography, Card, CardContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';

// Предположим, что вы уже реализовали метод в api.js
import { fetchAdminPanelData } from '../services/api';

// Переиспользуемый компонент для графиков
import DiagramCard from './DiagramCard';

const AdminPanel = ({ onClose, token }) => {
    const [loading, setLoading] = useState(false);
    const [adminData, setAdminData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true);
        setError(null);

        fetchAdminPanelData(token)
            .then((data) => {
                // Ожидаем формат:
                // {
                //   simpleCarts: [
                //     { "title": "Some Title", "body": "Строка HTML с <b>тегами</b>" }, ...
                //   ],
                //   diagrams: [
                //     {
                //       "title": "Revenue Week Diagram",
                //       "data": [
                //         { "keyX": "24-W5", "keyY": 12.34, "tooltip": 0.15 }, ...
                //       ]
                //     },
                //     ...
                //   ]
                // }
                setAdminData(data);
            })
            .catch((err) => {
                console.error('Failed to fetch admin panel data:', err);
                setError('Failed to fetch admin panel data...');
            })
            .finally(() => {
                setLoading(false);
            });
    }, [token]);

    // Пока грузим
    if (loading) {
        return (
            <Box
                sx={{
                    width: '100%',
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    // Ошибка
    if (error) {
        return <Box sx={{ p: 2 }}>{error}</Box>;
    }

    // Если данных нет
    if (!adminData) {
        return <Box sx={{ p: 2 }}>No data</Box>;
    }

    // Извлекаем нужные поля
    const { simpleCards, diagrams } = adminData;

    // Проверяем, что это массивы (если нужны)
    const isSimpleCardsArray = Array.isArray(simpleCards);
    const isDiagramsArray = Array.isArray(diagrams);

    return (
        <Box sx={{ p: 2 }}>
            {/* Кнопка-крестик */}
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                }}
            >
                <CloseIcon />
            </IconButton>

            {/* Заголовок всей страницы */}
            <Box sx={{ textAlign: 'center', pt: 4, pb: 2 }}>
                <Typography variant="h6">Admin Panel</Typography>
            </Box>

            {/* 1) Сначала рендерим карточки (если есть и это массив) */}
            {isSimpleCardsArray &&
                simpleCards.map((card, index) => (
                    <Card key={`simplecart-${index}`} sx={{ mb: 2 }}>
                        <CardContent>
                            <Typography variant="body1" gutterBottom>
                                {card.title}
                            </Typography>
                            {/* Если body может содержать HTML-теги, отображаем их: */}
                            <Typography
                                variant="body2"
                                component="div"
                                dangerouslySetInnerHTML={{ __html: card.body }}
                            />
                        </CardContent>
                    </Card>
                ))}

            {/* 2) Затем рендерим диаграммы (если есть и это массив) */}
            {isDiagramsArray &&
                diagrams.map((diagram, idx) => (
                    <DiagramCard
                        key={`diagram-${idx}`}
                        title={diagram.title}
                        chartData={diagram.data}
                        xKey="keyX"
                        yKey="keyY"
                        usdKey="tooltip"
                    />
                ))}

            {/* Отступ снизу, чтобы не уезжало под крестик */}
            <Box sx={{ pb: 12 }} />
        </Box>
    );
};

export default AdminPanel;