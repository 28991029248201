// src/components/SnackbarResult.js
import React from 'react';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';

import CloseIcon from '@mui/icons-material/CloseRounded';
import {parseMessage} from "../utils/markdown";

const SnackbarResult = ({ open, severity = 'info', message = '', onClose }) => {
    const htmlMessage = parseMessage(message);

    return (
        <Snackbar
            open={open}
            onClose={onClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            <Alert
                severity={severity}
                sx={{ width: '100%' }}
                action={
                    <IconButton color="inherit" size="small" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                }
            >
                <span dangerouslySetInnerHTML={{ __html: htmlMessage }} />
            </Alert>
        </Snackbar>
    );
};

export default SnackbarResult;