// src/components/MapTab.js

import React, { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Skeleton from '@mui/material/Skeleton';

import { AuthContext } from '../contexts/AuthContext';
import { connectSSE, sendTabChange } from '../services/api';
import { renderWidget } from '../utils/WidgetRenderer'; // <-- общий рендерер

const MapTab = () => {
    const token = useContext(AuthContext);

    // Массив виджетов, которые приходят по SSE
    const [widgets, setWidgets] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!token) return;

        setIsLoading(true);

        // Сообщаем серверу, что клиент открыл вкладку "map"
        sendTabChange(token, 'map');

        // upsertWidget: добавить или обновить виджет по ключу
        const upsertWidget = (widgetKey, dataObj) => {
            setWidgets((prev) => {
                const copy = [...prev];
                const idx = copy.findIndex((w) => w.k === widgetKey);
                if (idx === -1) {
                    // нет такого => добавляем
                    copy.push({
                        k: widgetKey,
                        type: dataObj.type || 'simple',
                        value: { ...dataObj },
                    });
                } else {
                    // обновляем
                    const updated = { ...copy[idx] };
                    updated.type = dataObj.type || updated.type;
                    updated.value = {
                        ...updated.value,
                        ...dataObj,
                    };
                    copy[idx] = updated;
                }
                return copy;
            });
        };

        // точечное обновление виджета
        const updateWidgetContent = (widgetKey, newValue) => {
            setWidgets((prev) => {
                const idx = prev.findIndex((w) => w.k === widgetKey);
                if (idx === -1) return prev; // нет такого
                const copy = [...prev];
                copy[idx] = {
                    ...copy[idx],
                    value: {
                        ...copy[idx].value,
                        ...newValue,
                    },
                };
                return copy;
            });
        };

        // Обработчики SSE
        const updateHandlers = {
            widgetsInit: (raw) => {
                try {
                    const arr = JSON.parse(raw);
                    setWidgets(arr);
                    setIsLoading(false);
                } catch (e) {
                    console.error('MapTab widgetsInit parse error:', e);
                }
            },
            widgetUpdate: (raw) => {
                try {
                    const obj = JSON.parse(raw);
                    const { k, value } = obj;
                    if (k && value) {
                        updateWidgetContent(k, value);
                    }
                } catch (e) {
                    console.error('MapTab widgetUpdate parse error:', e);
                }
            },
            // динам. ключи "M1", "M2" и т.п. (если api.js настроено на _widgetDynamic)
            _widgetDynamic: (widgetKey, rawValue) => {
                try {
                    const dataObj = (typeof rawValue === 'string') ? JSON.parse(rawValue) : rawValue;
                    upsertWidget(widgetKey, dataObj);
                    setIsLoading(false);
                } catch (e) {
                    console.error(`MapTab dynamic widget parse error for ${widgetKey}:`, e);
                }
            },
        };

        // Подключаем SSE
        const eventSource = connectSSE(
            token,
            updateHandlers,
            (err) => {
                console.error('MapTab SSE error:', err);
            },
            'map'
        );

        return () => {
            eventSource.close();
        };
    }, [token]);

    // Нет токена => показываем скелетон
    if (!token) {
        return (
            <Card sx={{ padding: 2 }}>
                <Skeleton variant="text" height={50} />
                <Box sx={{ height: 50 }} />
                <Skeleton variant="rectangular" height={100} />
                <Box sx={{ height: 50 }} />
                <Skeleton variant="text" height={50} />
            </Card>
        );
    }

    // Показать скелетон, пока не пришли данные
    if (isLoading) {
        return (
            <Card sx={{ padding: 2 }}>
                <Skeleton variant="text" height={50} />
                <Box sx={{ height: 50 }} />
                <Skeleton variant="rectangular" height={100} />
                <Box sx={{ height: 50 }} />
                <Skeleton variant="text" height={50} />
            </Card>
        );
    }

    // Рендерим всю верстку «как раньше», но вместо вручную рисовать RegionCard и т.д.,
    // вызываем renderWidget(widget).
    return (
        <Box
            sx={{
                bgcolor: 'background.default',
                overflow: 'visible', // было так в исходном MapTab
                py: { xs: 2, sm: 3, md: 4 },
                px: { xs: 1, sm: 2, md: 4 },
                boxSizing: 'border-box',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: { xs: 2, sm: 3, md: 4 }, // отступ между «карточками»-виджетами
            }}
        >
            {widgets.map((widget) => (
                <Box key={widget.k}>
                    {renderWidget(widget)}
                </Box>
            ))}
        </Box>
    );
};

export default MapTab;